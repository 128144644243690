<template>
  <v-container>
    <!-- Header -->
    <div class="text-center mt-5" v-if="!project">
      <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
      <p class="mt-2">Retrieving data ...</p>
    </div>
    <div v-else>
      <v-col align="start" justify="center" class="pa-0 mb-10">
        <v-icon color="primary" style="cursor: pointer" @click="$router.go(-1)">
          {{ icons.mdiChevronLeft }}
        </v-icon>
        <h3 style="display: inline-block" class="ms-4">
          {{ project ? project.name + ' - ' + formatDate(disbursement.disbursementDate) : '' }}
        </h3>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          v-model="search"
          :prepend-inner-icon="icons.mdiMagnify"
          label="Search employee"
          single-line
          hide-details
          dense
          outlined
        >
        </v-text-field>
      </v-col>

      <!-- Table -->
      <!-- <pre>{{ employeesWithPaymentMethodCashier }}</pre> -->
      <!-- <pre>{{ disbursement }}</pre> -->
      <v-data-table
        :headers="headers"
        :items="employees"
        item-key="id"
        class="elevation-1"
        :loading="loading"
        loading-text="Loading... Please wait"
      >
        <template v-slot:item.status="{ item }">
          <div :style="`color: ${employeeStatusColor[item.status]}`">
            {{ employeeStatusText[item.status] }}
          </div>
        </template>
        <template v-slot:item.amount="{ item }"> {{ formattedCurrency(item.amount) }}. </template>
        <template v-slot:item.currentTotalLoan="{ item }"> {{ formattedCurrency(item.currentTotalLoan) }}. </template>
        <template v-slot:item.totalReceived="{ item }"> {{ formattedCurrency(item.totalReceived) }}. </template>
        <template v-slot:item.action="{ item }">
          <v-btn
            v-if="item.status === 'sent' && item.paymentMethod === 'cashier-pin'"
            outlined
            color="primary"
            class="my-4 text-capitalized"
            width="200px"
            @click="openDialogConfirmPin(item.id)"
          >
            Confirm With Pin
          </v-btn>
          <v-btn
            v-if="item.status === 'sent' && item.paymentMethod === 'cashier-face'"
            outlined
            color="primary"
            class="my-4 text-capitalized"
            @click="openCamera(item.employeeId)"
          >
            Confirm With Face
          </v-btn>
          <!-- <DialogEnterPin
            v-model="showDialogEnterPin"
            :subId="subId"
            :disbursement-detail-id="detailDisbursementId"
            :employee-group-id="disbursement.employeeGroupId"
          /> -->
        </template>
      </v-data-table>
    </div>
    <DialogEnterPin2
      ref="dialogEnterPin"
      @finish="onFinish"
    />
  </v-container>
</template>

<script>
import useAppConfig from '@core/@app-config/useAppConfig'
import { ref } from '@vue/composition-api'
import { mdiMagnify } from '@mdi/js'
import DialogEnterPin from './components/DialogEnterPin.vue'
import DialogEnterPin2 from './components/DialogEnterPin2.vue'

export default {
  name: 'ListEmployee',
  components: { DialogEnterPin, DialogEnterPin2 },
  setup() {
    const { isDark } = useAppConfig()
    const search = ref('')
    const employeeStatusText = {
      waitingApproval: 'Waiting Approval',
      approved: 'Approved',
      sent: 'Sent',
      paid: 'Paid',
    }
    const employeeStatusColor = {
      waitingApproval: '#FCBE2D',
      approved: '#111B21',
      sent: '#999999',
      paid: '#44A780',
      failed: '#f44336',
    }
    const disbursementStatusColor = {
      done: '#44A780',
      inProgress: '#FCBE2D',
      approved: '#111B21',
      rejected: '#FF6666',
      waitingApproval: '#999999',
    }

    return {
      isDark,
      search,
      employeeStatusText,
      employeeStatusColor,
      disbursementStatusColor,
      icons: { mdiMagnify },
    }
  },
  data() {
    return {
      titles: ['Disbursement date', 'Employee', 'Employee paid', 'Amount', 'Status'],
      values: ['09 Sep 2022 17:39', 10, 10000000, 10000000, 'approved'],
      headers: [
        {
          text: 'Name',
          value: 'employeeName',
        },
        { text: 'Amount', value: 'amount' },
        { text: 'Loan', value: 'currentTotalLoan' },
        { text: 'Received', value: 'totalReceived' },
        { text: 'Status', value: 'status' },
        { text: 'Action', value: 'action' },
      ],
      showDialogEnterPin: false,
      detailDisbursementId: null,
      employeesWithPaymentMethodCashier: [],
      loading: true,
      project: null,
      subId: "",
    }
  },
  computed: {
    disbursement() {
      return this.$store.getters['manageSalary/getCurrentDisbursement']
    },
    employeeGroup() {
      return this.$store.getters['manageSalary/getCurrentEmployeeGroup']
    },
    employees() {
      return this.employeesWithPaymentMethodCashier
    },
  },
  async mounted() {
    this.subId = this.$route.params.sub_id_disbursement_id.split('-')[0];
    this.getEmployeesWithPaymentMethodCashier()
    await this.$store.dispatch('manageSalary/getDisbursementById', {
      sub_id: this.$route.params.sub_id_disbursement_id.split('-')[0],
      id: this.$route.params.sub_id_disbursement_id.split('-')[1],
    })
    await this.$store.dispatch('manageSalary/getEmployeeGroupById', {
      sub_id: this.$route.params.sub_id_disbursement_id.split('-')[0],
      id: this.disbursement.employeeGroupId,
    })
    await this.$store.dispatch('manageProject/getProjectById', {
      sub_id: this.$route.params.sub_id_disbursement_id.split('-')[0],
      id: this.employeeGroup.ID_project,
    })
    this.project = this.$store.getters['manageProject/getCurrentProject']
  },
  methods: {
    formattedCurrency(number) {
      return number ? new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(number) : 0
    },
    formatDate(date) {
      return this.$moment(new Date(date - new Date().getTimezoneOffset())).format('DD MMM YYYY HH:mm')
    },
    async openCamera(employeeId) {
      this.$router.push(`/manage-cashier/${this.$route.params.sub_id_disbursement_id}/confirm-with-face/${employeeId}`)
    },
    async getEmployeesWithPaymentMethodCashier() {
      const { status, data } = await this.$store.dispatch(
        'manageSalary/getDisbursementDetailWithPaymentMethodCashier',
        {
          sub_id: this.$route.params.sub_id_disbursement_id.split('-')[0],
          disbursementId: this.$route.params.sub_id_disbursement_id.split('-')[1],
        },
      )
      if (status) {
        this.loading = false
      }
      this.employeesWithPaymentMethodCashier = data;
    },
    openDialogConfirmPin(idDetailDisbursement) {
      this.$refs.dialogEnterPin.open(idDetailDisbursement)
    },
    onFinish() {
      this.getEmployeesWithPaymentMethodCashier();
    }
  },
}
</script>

<style lang="scss" scoped>
.v-btn.action-btn {
  width: 36px;
  min-width: 36px;
}
</style>
