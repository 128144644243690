<template>
  <v-col cols="auto">
    <v-dialog
      v-model="dialog"
      transition="dialog-top-transition"
      max-width="600"
    >
      <template>
        <v-card>
          <v-toolbar
            color="primary"
            dark
          >
            <!-- Please enter confirmation pin! {{ employeeGroupId }} -->
          </v-toolbar>
          <v-card-text>
            <div
              class="ma-auto position-relative mt-10"
              style="max-width: 300px"
            >
              <v-otp-input
                v-model="otp"
                :disabled="loading"
                type="password"
                length="8"
                @finish="onFinish"
              ></v-otp-input>
              <v-overlay
                absolute
                :value="loading"
              >
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </v-overlay>
            </div>

            <v-snackbar
              v-model="snackbar"
              :color="snackbarColor"
              :timeout="2000"
            >
              <div class="text-center">
                {{ text }}
              </div>
            </v-snackbar>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              outlined
              color="primary"
              text
              @click="resetFormInput"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </v-col>
</template>

<script>
export default {
  name: 'DialogEnterPin',
  props: {
    value: { type: Boolean, required: false },
    disbursementDetailId: {
      type: String,
      required: false,
      default() {
        return null
      },
    },
    employeeGroupId: {
      type: String,
      required: false,
      default() {
        return null
      },
    },
    subId: { type: String, required: false },
  },
  data: () => ({
    loading: false,
    snackbar: false,
    snackbarColor: '',
    otp: '',
    text: '',
  }),
  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    async onFinish(pin) {
      this.loading = true
      const disbursementDetail = await this.$store.dispatch('manageSalary/getDisbursementDetailById', {
        sub_id: this.subId,
        id: this.disbursementDetailId,
      })
      //console.log(disbursementDetail, 'disbursementDetail')
      const res = await this.$store.dispatch('manageSalary/checkPinDisbursement', {
        sub_id: this.subId,
        pinDetailDisbursement: pin,
        id: disbursementDetail.data.data.id,
        employeeGroupId: this.employeeGroupId,
      })
      if (!res) {
        this.loading = true
        this.snackbarColor = 'danger'
        this.text = `Pin is not match`
        this.snackbar = true
        window.location.reload()
      } else {
        await this.$store.dispatch('manageSalary/updateDisbursmentToPaid', {
          sub_id: this.subId,
          id: this.disbursementDetailId,
        })
        await this.$store.dispatch('manageSalary/getDisbursementById', {
          sub_id: this.subId,
          id: this.$route.params.idDisbursement,
        })
        this.loading = false
        this.snackbarColor = 'success'
        this.text = `Status payment has been completed`
        this.snackbar = true
      }
      setTimeout(() => {
        this.dialog = false
        this.resetFormInput()
      }, 1000)
    },
    resetFormInput() {
      this.dialog = false;
      this.otp = ''
      this.loading = false
      this.snackbar = true
    },
  },
}
</script>

<style scoped>
.position-relative {
  position: relative;
}
</style>