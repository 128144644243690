<template>
  <div>
    <v-dialog v-model="dialog" v-if="dialog" transition="dialog-top-transition" max-width="600">
      <template>
        <v-card>
          <v-toolbar color="primary" dark> Please enter confirmation pin </v-toolbar>
          <v-card-text>
            <div class="ma-auto position-relative mt-10" style="max-width: 300px">
              <v-otp-input
                v-model="otp"
                :disabled="loading"
                type="password"
                length="8"
                @finish="onFinish"
              ></v-otp-input>
              <v-overlay absolute :value="loading" class="text-center">
                <v-progress-circular indeterminate color="primary" class="mt-3"></v-progress-circular>
                <p>Verifying Pin ...</p>
              </v-overlay>
            </div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn outlined color="primary" text @click="resetFormInput"> Close </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <v-snackbar class="mb-5" v-model="snackbar" :color="snackbarColor" :timeout="2000">
      <div class="text-center">
        {{ text }}
      </div>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: 'DialogEnterPin2',
  emits: ['finish'],
  data: () => ({
    dialog: false,
    loading: false,
    snackbar: false,
    snackbarColor: 'default',
    otp: '',
    text: '',
    idDetailDisbursement: null,
  }),
  methods: {
    open(idDetailDisbursement) {
      this.dialog = true
      this.idDetailDisbursement = idDetailDisbursement
    },
    async onFinish() {
      this.loading = true
      const { status, message } = await this.$store.dispatch('manageSalary/checkPinDisbursement', {
        sub_id: this.$route.params.sub_id_disbursement_id.split('-')[0],
        pin: this.otp,
        id: +this.idDetailDisbursement,
      })

      if (status) {
        this.snackbarColor = 'primary'
        this.text = message
        this.$emit('finish');
      } else {
        this.snackbarColor = '#E53935'
        this.text = message
      }

      setTimeout(() => {
        this.snackbar = true
        this.loading = false
        this.dialog = false
      }, 1000)
      setTimeout(() => {
        this.resetFormInput()
      }, 3000)
    },
    resetFormInput() {
      this.dialog = false
      this.snackbar = false
      this.otp = ''
    },
  },
}
</script>

<style scoped>
.position-relative {
  position: relative;
}
</style>
